import type { Directive } from 'vue';
import { getByName } from './composables.Target.ts';
interface HTMLTargetElement extends HTMLElement {
    __o_target?: {
        handler?: (pEvent: Event) => void,
        event?: string
    }
};
type TargetDirectiveBinding = { handler: Function, event?: string,}
const vTarget: Directive<HTMLTargetElement, TargetDirectiveBinding> = {
    mounted(el, binding) {
        const target = getByName(binding.arg);
        if (target) {
            el.__o_target = {};
            el.__o_target.handler = binding.value?.handler
                ? (...args: any) => binding.value.handler(target.handler, el, ...args)
                : (...args: any) => target.handler(el, ...args);
            el.__o_target.event = binding.value?.event ?? target.event;
            el.addEventListener(target.event, el.__o_target.handler);
        } else {
            console.warn(`No target found with name ${binding.arg}`);
        }
    },

    unmounted(el: any) {
        if (el.__o_target) {
            el.removeEventListener(el.__o_target.event, el.__o_target.handler);
        }
    }
}

export default vTarget;